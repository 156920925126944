import { FeatureAppLoader } from '@feature-hub/react'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import { ADACServiceContext } from './service-context'

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    background: rgba(255, 255, 255, 1);
    font-family: MiloSlabADAC,sans-serif;
    font-feature-settings: "lnum";
  }
`

const StyledHead = styled.div`
  height: 64px;
  background-color: rgb(255, 204, 0);
  padding: 1em;
`

const StyledBody = styled.div`
  min-height: calc(100vh - 300px);
  padding: 1em;
`

const StyledBreadcrumb = styled.div`
  height: 60px;
  padding: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.9);
`

const StyledFooter = styled.div`
  height: 180px;
  width: 100%;
  padding: 1em;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  text-align: center;
`

export const App: React.FC<{ baseUrl?: string }> = ({ baseUrl }) => {
  const adac = useContext(ADACServiceContext)
  const breadcrumbs = adac.breadcrumbs.use()

  return (
    <div>
      <GlobalStyle />
      <StyledHead>
        <h2>ADAC</h2>
      </StyledHead>
      {breadcrumbs.length ? (
        <StyledBreadcrumb>
          {breadcrumbs.map(({ path, label }, i) => (
            <span key={path}>
              {i > 0 ? ' > ' : undefined}
              <Link to={path}>{label}</Link>
            </span>
          ))}
        </StyledBreadcrumb>
      ) : undefined}
      <StyledBody>
        <FeatureAppLoader
          baseUrl={baseUrl}
          moduleType="federated"
          featureAppId={`adac:feature-app:forderungs-checker-croatia`}
          src={`/forderungs-checker-croatia/forderungs-checker-croatia.client.js`}
          serverSrc={
            baseUrl ? `/forderungs-checker-croatia/forderungs-checker-croatia.ssr.js` : undefined
          }
          css={[{ href: '/forderungs-checker-croatia/css/styles.css', media: 'all' }]}
        />
      </StyledBody>

      <StyledFooter>
        <span>©2024 ADAC</span>
      </StyledFooter>
    </div>
  )
}
